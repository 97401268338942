import React, { memo, useEffect } from 'react';
import { styled } from 'stitches.config';

import { FooterComponent } from '~components/organisms/FooterComponent/FooterComponent';
import { MobileHeaderContainer } from '~components/organisms/HeaderComponent/Header';
import { HeaderComponent } from '~components/organisms/HeaderComponent/HeaderComponent';
import { MobileContentContainer } from '~components/organisms/MobileContentContainer';
import { MobileTabComponent } from '~components/organisms/MobileTabComponent';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { RouteConfig } from '~routes';
import { useAppDispatch } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';

const MobileLayoutContainer = styled('div', {
  height: '100%',
});

const activeMobileActiveTabsPages = [
  ROUTE_TAB_NAMES.BASE,
  ROUTE_TAB_NAMES.BETSLIP,
];

export const MobilePageTemplate = memo(
  ({ header, footer, component, path }: RouteConfig) => {
    const isBetslip = path === ROUTE_TAB_NAMES.BETSLIP;
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!activeMobileActiveTabsPages.includes(path)) {
        dispatch(setMobileActiveTab(null));
      }
    }, [path]);

    return (
      <MobileLayoutContainer>
        <MobileHeaderContainer>
          {header !== undefined ? header : <HeaderComponent path={path} />}
        </MobileHeaderContainer>
        <MobileContentContainer>{component}</MobileContentContainer>
        {footer !== undefined ? footer : <FooterComponent path={path} />}
        {!isBetslip && <MobileTabComponent />}
      </MobileLayoutContainer>
    );
  },
);
