import { useLayoutEffect, useRef, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks';

export type ToggleType =
  | 'toggle'
  | 'bet'
  | 'market'
  | 'payment'
  | 'userProfileMobileTab'
  | 'lightMarket';

export const ToggleGroupItemComponent = ({
  type = 'market',
  market,
  marketSelected,
  sportId,
}: {
  type?: ToggleType;
  market: MainMarket;
  marketSelected: Record<number, number>;
  sportId: number;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { isMobileOrTablet } = useMedia();
  const spanRef = useRef<HTMLSpanElement | null>(null);

  const { name, shortName, id } = market;
  const showShortName =
    (isMobileOrTablet && shortName) || (isOverflowing && shortName);

  useLayoutEffect(() => {
    // Check if the span element is overflowing
    const spanElement = spanRef.current;

    if (spanElement) {
      setIsOverflowing(spanElement.scrollWidth > spanElement.clientWidth);
    }
  }, []);

  return (
    <ToggleGroupItem
      key={name}
      type={type}
      value={id}
      disabled={id === marketSelected[sportId]}
    >
      <Text ref={spanRef} as="span">
        {showShortName ? shortName : name}
      </Text>
    </ToggleGroupItem>
  );
};
