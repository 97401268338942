import React from 'react';
import * as ToggleGroupUI from '@radix-ui/react-toggle-group';
import { CSS, styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';

import { BasicButton } from './Button/Button';

export const ToggleGroup = styled(ToggleGroupUI.Root, {
  borderRadius: '$8',
  display: 'flex',
  width: '100%',
  variants: {
    variant: {
      toggle: {
        border: '1px solid $green',
        gap: '$1',
        p: '$1',
      },
      bet: {
        gap: '1px',
      },
      market: {
        border: '1px solid $gray',
        px: '2px',
      },
      lightMarket: {
        boxShadow: '$toggleMarkets',
        px: '2px',
      },
      payment: {
        border: '1px solid $gray',
        background: '$grayMain',
      },
      userProfileMobileTab: {
        border: '1px solid $gray',
        borderRadius: '$6',
        p: '2px',
        gap: '$1',
      },
    },
  },
  defaultVariants: {
    variant: 'toggle',
  },
});

export const ToggleGroupButton = styled(BasicButton, {
  display: 'flex',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'inherit',
  width: '100%',
  alignItems: 'center',
  flexGrow: '1',
  backgroundColor: 'transparent',
  color: '$white',
  fontSize: '$14',
  lineHeight: '$20',
  ...ellipsis,
  variants: {
    variant: {
      toggle: {
        textAlign: 'center',
        pt: '7px',
        pb: '5px',
        textTransform: 'uppercase',
        gap: '$1',
        textShadow: '$buttonText',
        justifyContent: 'center',
        borderRadius: '$4',
        '&:hover': {
          backgroundColor: '$green',
        },
        '&[data-state=on]': {
          color: '$buttonTextColor',
          backgroundColor: '$green',
        },
        '&[data-state=off]': {
          backgroundColor: 'transparent',
        },
        '& .label': {
          fontWeight: '$medium',
        },
        '& .count': {
          fontWeight: '$normal',
        },
        '&[data-state=on] *, &:hover *': {
          fontWeight: '$bold',
        },
        '&[data-state=on] .count, &:hover .count': {
          fontWeight: '$medium',
        },
      },
      bet: {
        backgroundColor: '$gray',
        px: '$3',
        py: '$1',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: '$oddBgColorHover',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$oddBgColorDisabled',
        },
        '&:first-child': {
          borderTopLeftRadius: '$6',
          borderBottomLeftRadius: '$6',
        },
        '&:last-child': {
          borderTopRightRadius: '$6',
          borderBottomRightRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$oddBgColorActive',
          color: '$oddTextColorActive',
          '*': {
            color: '$oddTextColorActive',
          },
        },
      },
      market: {
        backgroundColor: 'transparent',
        width: '100%',
        py: '$1',
        m: '$1 2px',
        borderRadius: 0,
        '& > span': {
          color: '$white',
          fontSize: '$xxxs',
          width: '100%',
          textAlign: 'center',
          lineHeight: '$20',
          fontWeight: '$medium',
          textShadow: '$generalTextShadow',
        },
        '&:hover': {
          backgroundColor: '$gray',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$gray',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$gray',
          fontWeight: '$bold',
          color: '$white',
          '*': {
            color: '$white',
          },
          '> span': {
            fontWeight: '$bold',
          },
        },
      },
      lightMarket: {
        backgroundColor: '$gray',
        width: '100%',
        py: '$1',
        m: '$1 2px',
        borderRadius: 0,
        '& > span': {
          color: '$white',
          fontSize: '$xxxs',
          width: '100%',
          textAlign: 'center',
          lineHeight: '$20',
          fontWeight: '$medium',
        },
        '&:hover': {
          backgroundColor: '$gray',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$grayDark',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$grayDark',
          fontWeight: '$bold',
          color: '$white',
          '*': {
            color: '$white',
          },
          '> span': {
            fontWeight: '$bold',
          },
        },
      },
      userProfileMobileTab: {
        backgroundColor: '$black',
        textShadow: '$buttonText',
        width: '100%',
        p: '6px',
        borderRadius: '$4',
        '& > span': {
          color: '$white',
          fontSize: '$14',
          lineHeight: '$20',
          width: '100%',
          textAlign: 'center',
          fontWeight: '$medium',
          textTransform: 'uppercase',
        },
        '&:hover': {
          backgroundColor: '$gray',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$gray',
        },
        '&[data-state=on]': {
          backgroundColor: '$gray',
          fontWeight: '$bold',
          color: '$white',
          '*': {
            color: '$white',
          },
        },
        '&[data-state=on] > span': {
          fontWeight: '$bold',
        },
      },
      payment: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        p: '6px $2',
        gap: '$1',
        margin: '$1',
        borderRadius: 0,
        '& > div > svg': {
          width: '28px',
          height: '28px',
        },
        '& > span': {
          whiteSpace: 'normal',
          overflow: 'hidden',
          textTransform: 'uppercase',
          textAlign: 'left',
          color: '$white',
          fontSize: '$xxs',
          lineHeight: '$16',
          fontWeight: '$medium',
        },
        '&:hover': {
          backgroundColor: '$gray',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$gray',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$gray',
          fontWeight: '$bold',
          color: 'yellow',
          '*': {
            color: '$yellow',
          },
        },
        '&[data-state=on] > span': {
          fontWeight: '$bold',
        },
        '@xs_sm': {
          flexDirection: 'column',
          p: '$1',
          gap: '$1',
          '& > span': {
            textAlign: 'center',
            fontSize: '$10',
            lineHeight: '$12',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'toggle',
  },
});

interface ToggleGroupItemProps {
  value: string | number;
  children: React.ReactNode;
  type?:
    | 'toggle'
    | 'bet'
    | 'market'
    | 'payment'
    | 'userProfileMobileTab'
    | 'lightMarket';
  disabled?: boolean;
  css?: CSS;
}

export const ToggleGroupItem = ({
  value,
  children,
  disabled = false,
  type = 'toggle',
  css,
}: ToggleGroupItemProps) => {
  return (
    <ToggleGroupUI.Item
      css={css}
      onClick={(e) => e.stopPropagation()}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      value={value}
      asChild
      disabled={disabled}
    >
      <ToggleGroupButton onClick={(e) => e.stopPropagation()} variant={type}>
        {children}
      </ToggleGroupButton>
    </ToggleGroupUI.Item>
  );
};
