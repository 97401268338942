import { useEffect, useRef } from 'react';

import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setEventSocketConntected,
  setEventsSocket,
} from '~store/slices/signalRSocketsSlice';
import {
  ACTION_TYPE,
  getActionMessage,
  SOCKET_URL,
} from '~utils/eventsSocketUtils';

export const useLaunchEventsSocket = () => {
  const { isDisconnected } = useNetworkStatus();
  const dispatch = useAppDispatch();
  const { eventsSocket } = useAppSelector((state) => state.signalRSockets);
  const pingInterval = useRef<NodeJS.Timeout>();
  const initSocket = () => {
    dispatch(setEventSocketConntected(false));
    dispatch(setEventsSocket(new WebSocket(SOCKET_URL)));
  };

  const handleOpen = () => {
    dispatch(setEventSocketConntected(true));

    pingInterval.current = setInterval(() => {
      try {
        eventsSocket?.send(getActionMessage(ACTION_TYPE.PING));
      } catch (e) {
        console.log(e);
      }
    }, 4000);
  };

  useEffect(() => {
    if (!eventsSocket) {
      initSocket();
    }
  }, [eventsSocket]);

  useEffect(() => {
    if (!eventsSocket) return;

    const handleError = (error: unknown) => {
      console.error('WebSocket error', error);
    };

    eventsSocket.addEventListener('open', handleOpen);
    eventsSocket.addEventListener('error', handleError);

    return () => {
      eventsSocket.removeEventListener('open', handleOpen);
      eventsSocket.removeEventListener('error', handleError);
      pingInterval.current && clearInterval(pingInterval.current);
    };
  }, [eventsSocket]);

  useEffect(() => {
    if (isDisconnected && eventsSocket) {
      eventsSocket?.close();
    }
  }, [isDisconnected, eventsSocket]);

  useEffect(() => {
    if (eventsSocket?.readyState !== WebSocket.OPEN) {
      dispatch(setEventSocketConntected(false));
      if (eventsSocket?.readyState === WebSocket.CLOSED) {
        initSocket();
      }
    }
  }, [eventsSocket, eventsSocket?.readyState]);
};
