import { useEffect } from 'react';

import BetonlyFaviconIcon from '~assets/betonly-logo.webp';
import SemabetFaviconIcon from '~assets/semabet-logo.webp';

export const useGetFaviconUrl = () => {
  useEffect(() => {
    function getFaviconUrl() {
      const hostname = window.location.hostname;

      if (
        hostname.includes('semabet.ug') ||
        hostname.includes('semabet.co.zm') ||
        hostname.includes('semabet.co.mz') ||
        hostname.includes('semabet.co.tz')
      ) {
        return SemabetFaviconIcon;
      }

      if (hostname.includes('betonly')) {
        return BetonlyFaviconIcon;
      }

      return null;
    }

    if (document && document.querySelector("link[rel='icon']")) {
      const element = document.querySelector("link[rel='icon']");

      if (element) {
        const favicon = getFaviconUrl();

        if (favicon) {
          element.setAttribute('href', favicon);
        }
      }
    }
  }, []);
};
