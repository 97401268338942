import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const MainWrapper = styled(Box, {
  borderRadius: '$8',

  '> div': {
    borderRadius: '$8',
  },

  variants: {
    isAggregatedBet: {
      true: {
        '@xs_sm': {
          borderRadius: 0,
          borderTop: '1px solid $grayDarker',

          '> div': {
            borderRadius: 0,
          },

          '&:first-child': {
            borderRadius: '$8 $8 0 0',
            borderTop: 'none',
            '& > div': {
              borderRadius: '$8 $8 0 0',
            },
          },
          '&:last-child': {
            borderRadius: '0 0 $8 $8',
            '& > div': {
              borderRadius: '0 0 $8 $8',
            },
          },
        },
      },
    },
  },
});

export const StyledBetslipStakeWrapper = styled(Box, {
  display: 'flex',
  backgroundColor: '$grayDarkMain2',
  flexDirection: 'column',
  p: '$2 $3',
  gap: '$2',
  '@xs_sm': {
    gap: 0,
  },
  variants: {
    // If we have errors, we display the error message below the stake card
    // To follow the design, we need to remove the border radius from the bottom of the stake card
    isInvalid: {
      true: {
        borderRadius: '$8 $8 0 0 !important',
      },
    },
  },
});

export const StyledBetContainer = styled(Box, {
  padding: '$0 $4 $4',
  width: '100%',
  gap: '$2',
});

export const StyledBetsText = styled(Text, {
  fontSize: '$14',
  lineHeight: '$16',
  mb: '$3',
  '@xs_sm': {
    fontSize: '$12',
  },
});

export const StyledBetPlacedText = styled(Text, {
  fontSize: '16px',
  lineHeight: '28px',
  '@sm': {
    fontSize: '18px',
    lineHeight: '28px',
  },
  '@xs': {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

export const StyledPlacedBetIdText = styled(Text, {
  fontSize: '12px',
  lineHeight: '20px',
  '@sm': {
    fontSize: '14px',
  },
});

export const StyledQuickBetInfoWrapper = styled(Box, {
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  p: '$3',
  variants: {
    quickBet: {
      true: {
        backgroundColor: '$grayDarkMain2',
      },
    },
  },
});

export const StyledShareBetButton = styled(Button, {
  width: '100%',
  padding: '6px 0 !important',
  gap: '$2',
  '@sm': {
    padding: '$1 0 !important',
  },
  '@xs': {
    padding: '6px 0 !important',
  },
});

export const StyledShareButtonText = styled(Text, {
  fontSize: '12px',
  lineHeight: '16px',
  '@sm': {
    fontSize: '16px',
    lineHeight: '28px',
  },
  '@xs': {
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
});

export const StyledBetslipInfoActionButton = styled(Button, {
  width: '100%',
  padding: '$1 0 !important',
  '@sm': {
    padding: '10px 0 !important',
  },
  '@xs': {
    padding: '8px 0 !important',
  },
});

export const StyledBetslipInfoActionText = styled(Text, {
  fontSize: '16px',
  lineHeight: '28px',
  '@sm': {
    fontSize: '18px',
    lineHeight: '24px',
  },
});

export const StyledBetslipEmptyWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$1',
  padding: '$4 0',
  '@xs_sm': {
    paddingTop: '$12',
  },
  '@xs': {
    padding: '$12 $10',
  },
});

export const StyledBetslipFooterWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$10',
  zIndex: 1,
  background: '$panelBgColor',
  '@xs_sm': {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  variants: {
    static: {
      true: {
        '@xs_sm': {
          position: 'static',
        },
      },
    },
  },
});

export const StyledAdjustmentButton = styled(Button, {
  color: '$grayMedium',
  background: '$gray',
  p: '$3 $2',
  '@xs_sm': {
    fontSize: '$xxs',
    height: '32px',
    p: '$2 $2',
    lineHeight: '$20',
  },
});

export const StyledBetslipInputContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$2',
  '@xs_sm': {
    mt: '$3',
  },
});

export const StyledEventFinishedText = styled(Text, {
  color: '$red',
  fontWeight: '$medium',
  textAlign: 'center',
  fontSize: '$xs',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
  },
});
