import { useChangeDefaultBalance } from '~api/user/userMutations';
import { Box } from '~components/atoms/Box';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import { Text } from '~components/atoms/Typography';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useUserBalance } from '~hooks/useUserBalance';
import {
  setDefaultBalance,
  USER_DEFAULT_BALANCE,
} from '~store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '~store/store';

import {
  StyledBalanceInfoText,
  StyledBalanceInfoWrapper,
} from './styled.components';

export const UserProfileSidebarBalances = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { defaultBalance, profile } = useAppSelector(
    (state) => state.userState,
  );
  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const { changeDefaultBalanceMutation } = useChangeDefaultBalance();
  const isCasinoWebsite = useIsCasinoWebsite();
  const { balance, freeBetBalance } = profile || {
    balance: 0,
    freeBetBalance: 0,
  };
  const { mainBalanceText, freeBetBalanceText } = useUserBalance(
    balance,
    freeBetBalance,
  );

  const onDefaultBalanceChange = async (value: string) => {
    const newTypeValue = parseInt(value) as USER_DEFAULT_BALANCE;

    dispatch(setDefaultBalance(newTypeValue));

    try {
      await changeDefaultBalanceMutation(newTypeValue).unwrap();
    } catch (error) {
      console.error('Error during changing preferred balance type', error);
    }
  };

  const BalanceNumberText = ({
    isBonusBalanceField = false,
  }: {
    isBonusBalanceField?: boolean;
  }) => (
    <Text
      textAlign="right"
      level={isMobileOrTablet ? '14-20' : '14-24'}
      ellipsis
      color="yellow"
      css={{
        minWidth: '100px',
        '@xs_sm': {
          minWidth: '50px',
        },
      }}
    >
      {isBonusBalanceField ? freeBetBalanceText : mainBalanceText}
    </Text>
  );

  return (
    <RadioGroupRoot
      value={defaultBalance.toString()}
      onValueChange={onDefaultBalanceChange}
    >
      <Box flexCol gap={2}>
        <StyledBalanceInfoWrapper>
          <Box flexRow gap={3} alignCenter>
            {/* If separateBonusBalance option set to false - give user a possibility to select balance */}
            {!separateBonusBalance && (
              <StyledRadioGroupItem
                className="RadioGroupItem"
                value={USER_DEFAULT_BALANCE.MAIN.toString()}
              >
                <StyledRadioGroupItemIndicator />
              </StyledRadioGroupItem>
            )}
            <StyledBalanceInfoText>
              {localized('userProfile.personalProfileInfo.mainBalance')}
            </StyledBalanceInfoText>
          </Box>
          <BalanceNumberText />
        </StyledBalanceInfoWrapper>
        {!isCasinoWebsite && (
          <StyledBalanceInfoWrapper>
            <Box flexRow gap={3} alignCenter>
              {/* If separateBonusBalance option set to false - give user a possibility to select balance */}
              {!separateBonusBalance && (
                <StyledRadioGroupItem
                  className="RadioGroupItem"
                  value={USER_DEFAULT_BALANCE.BONUS.toString()}
                >
                  <StyledRadioGroupItemIndicator />
                </StyledRadioGroupItem>
              )}
              <StyledBalanceInfoText>
                {localized('userProfile.personalProfileInfo.bonusBalance')}
              </StyledBalanceInfoText>
            </Box>
            <BalanceNumberText isBonusBalanceField={true} />
          </StyledBalanceInfoWrapper>
        )}
      </Box>
    </RadioGroupRoot>
  );
};
