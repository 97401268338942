import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';

export const StyledFooter = styled(Box, {
  display: 'grid',
  gridArea: 'auto',
  height: 'fit-content',
  backgroundColor: '$blackMain',
  p: '58px 32px $5',
  variants: {
    isInfoPage: {
      true: {
        '@media(min-width: 1920px)': {
          width: '1920px',
        },
      },
    },
  },
});

export const StyledSettingsWrapper = styled(Box, {
  width: '2.5rem',
  height: '2.5rem',
  position: 'absolute',
  top: '$5',
  right: '$5',
});

export const StyledMobileFooter = styled(Box, {
  backgroundColor: '$blackMain',
  position: 'relative',
  py: '$6',
  width: '100%',
});

export const StyledSubscribeEmailContainer = styled(Box, {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  borderRadius: '$8',
  border: '1px solid $green',
  p: '$1',
  '@xs_sm': {
    borderRadius: '$6',
  },
});

export const StyledInput = styled(Input, {
  width: '100%',
  fontSize: '$18',
  lineHeight: '$24',
  backgroundColor: '$black',
  '@xs_sm': {
    fontSize: '$14',
    lineHeight: '$20',
  },
});

export const StyledSubscribeButton = styled(Button, {
  minWidth: '172px',
  py: '$2',
  fontSize: '$18',
  lineHeight: '$24',
  fontWeight: '$bold',
  borderRadius: '$6',
  '@xs_sm': {
    minWidth: '100px !important',
    fontSize: '$12 !important',
    lineHeight: '$20 !important',
    borderRadius: '$4 !important',
    py: '$1 !important',
  },
});

export const StyledSubscribeText = styled(Text, {
  color: '$green !important',
  fontSize: '$16 !important',
  lineHeight: '$24 !important',
  fontWeight: '$bold !important',
  textTransform: 'uppercase',
});

export const StyledLinkContentMenuText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$20 !important',
  '@xs_sm': {
    lineHeight: '$24 !important',
  },
});

export const StyledMenuText = styled(Text, {
  fontSize: '$16 !important',
  lineHeight: '$24 !important',
  textTransform: 'uppercase',
  fontWeight: '$medium !important',
  '@xs_sm': {
    fontSize: '$14 !important',
    lineHeight: '$20 !important',
  },
});

export const StyledBottomPlaceholder = styled(Box, {
  display: 'flex',
  backgroundColor: '$grayDark',
  width: '100%',
  borderRadius: '$6',
});

export const StyledThanksForSubscription = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  p: '$2',
  borderRadius: '$6',
  backgroundColor: '$greenDarker',
});
