import { useEffect } from 'react';

import { UserProfileData } from '~api/user/types';
import { useLazyGetUserProfile } from '~api/user/userQueries';
import {
  startTokenRefreshScheduler,
  stopTokenRefreshScheduler,
} from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch, useAppSelector } from '~store';
import {
  logout,
  selectIsUserLoggedIn,
  setIsUserProfileLoaded,
  setProfile,
} from '~store/slices/userSlice';

export const useLoadUserProfile = () => {
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const { lazyGetUserProfileQuery } = useLazyGetUserProfile();

  useEffect(() => {
    const loadProfile = async () => {
      try {
        dispatch(setIsUserProfileLoaded(false));
        const { data } = await lazyGetUserProfileQuery();

        if (data) {
          dispatch(setProfile(data as UserProfileData));
          dispatch(setIsUserProfileLoaded(true));
        } else {
          dispatch(logout());
          window.parent.postMessage({ type: 'LOGOUT' }, '*');
        }
      } catch (error) {
        console.error('Error while loading profile', error);
        dispatch(logout());
        window.parent.postMessage({ type: 'LOGOUT' }, '*');
      }
    };

    if (isUserLoggedIn) {
      loadProfile();
      startTokenRefreshScheduler();
    } else {
      stopTokenRefreshScheduler();
    }
  }, [isUserLoggedIn]);
};
