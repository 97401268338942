import { useEffect, useLayoutEffect, useState } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { QUERY_PARAMS } from '~constants/common';
import { THEMES } from '~constants/ui';
import {
  useCasinoDataLoad,
  useLaunchEventsSocket,
  useListenEventsLoadingSocket,
  useQueryParams,
  useRouterQuery,
  useShare,
} from '~hooks';
import { useGetFaviconUrl } from '~hooks/useGetFaviconUrl';
import { useIframeAuthorization } from '~hooks/useIframeAuthorization';
import { useListenTokens } from '~hooks/useListenTokens';
import { useLoadBannerGroups } from '~hooks/useLoadBannerGroups';
import { useLoadFavouriteMarkets } from '~hooks/useLoadFavouriteMarkets';
import { useLoadSettings } from '~hooks/useLoadSettings';
import { useLoadUserLimits } from '~hooks/useLoadUserLimits';
import { useLoadUserProfile } from '~hooks/useLoadUserProfile';
import { useSetupPlainSockets } from '~hooks/useSetupPlainSockets';
import { useSignalRSockets } from '~hooks/useSignalRSockets';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import { useUpdateWebsiteSettings } from '~hooks/useUpdateSettings';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { login, selectUserProfileLanguageId } from '~store/slices/userSlice';
import { setLanguage } from '~store/slices/websiteSettings';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { getTheme } from '~utils/getTheme';

import { semaBetTheme } from '../stitches.config';

export const useOnAppInit = () => {
  const dispatch = useAppDispatch();

  const { verifyEmailToken, name } = useQueryParams();
  const { removeQueryParams } = useRouterQuery();

  const languageId = useAppSelector(selectUserProfileLanguageId);
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);

  useCasinoDataLoad();
  useIframeAuthorization();
  useShare();
  useLaunchEventsSocket();
  useListenEventsLoadingSocket();
  useTransactionsUpdate();
  useListenTokens();
  useUpdateWebsiteSettings();
  useSignalRSockets();

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }
  }, []);

  useLayoutEffect(() => {
    removeQueryParams([QUERY_PARAMS.NEXT_HOUR]);
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      dispatch(login());
    }

    const theme = getTheme();

    if (theme === THEMES.SEMA_BET) {
      document.documentElement.classList.add(semaBetTheme.className);
    }
  }, []);

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  useSetupPlainSockets();
  useLoadBannerGroups();
  useLoadUserProfile();

  useEffect(() => {
    if (!languageId) return;

    dispatch(setLanguage(languageId));
  }, [languageId]);

  useGetFaviconUrl();
  useLoadSettings(setIsSettingsLoaded);
  useLoadUserLimits(isSettingsLoaded);
  useLoadFavouriteMarkets();
};
