import { useEffect, useRef } from 'react';

import { PrematchParams } from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useMedia } from '~hooks/useMedia';
import { useNextHourToDateTo } from '~hooks/useNextHourToDateTo';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsLoading,
  setLoadByDate,
  setOpenedCountries,
  setOpenedSports,
  setReloadPrematch,
} from '~store/slices/prematchMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { conditionalAdd, convertKeysToUpperCase } from '~utils/objectHelpers';

export const useSportWithCurrentLeague = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { countryId, leagueId, sportId } = useQueryParams();
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();
  const {
    sports = [],
    reloadPrematch,
    isLoading,
  } = useAppSelector((state) => state.prematchMenu);
  const sportsRef = useRef(sports);
  const { activeSportTabGroup: activeTabGroup } = useAppSelector(
    (state) => state.mobileState,
  );
  const dateTo = useNextHourToDateTo();
  const prevDateToValue = useRef(dateTo);
  const language = useAppSelector(selectLanguage);

  const loadingRef = useRef(isLoading);
  const sportIdRef = useRef(sportId);

  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    sportsRef.current = sports;
  }, [sports]);

  useEffect(() => {
    if (sportId && sportId !== sportIdRef.current) {
      dispatch(setReloadPrematch(true));
    }
  }, [sportId]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleLoadPrematch = async () => {
      const isClearedDateTo = !dateTo && !!prevDateToValue.current;

      prevDateToValue.current = dateTo;

      if (dateTo && !isClearedDateTo) {
        dispatch(setIsLoading(false));

        return;
      }

      if (sportId && !isClearedDateTo) {
        dispatch(setIsLoading(true));
        const loadedSportCountries = sportsRef.current.find(
          ({ id }) => id === parseInt(sportId),
        )?.countries;

        if (loadedSportCountries && loadedSportCountries.length) {
          const loadedCountriesIds = loadedSportCountries
            .filter(({ leagues }) => {
              return leagues.length > 0;
            })
            .map(({ id }) => id.toString());

          dispatch(setOpenedCountries(loadedCountriesIds));

          if (isMobileOrTablet) {
            timeoutId = setTimeout(() => {
              dispatch(setIsLoading(false));
            }, 200);
          } else {
            dispatch(setIsLoading(false));
          }

          return;
        }

        const params: Partial<PrematchParams> = {};

        if (sportId) {
          conditionalAdd(params, QUERY_PARAMS.SPORT_ID, Number(sportId));
        }

        if (countryId) {
          conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, Number(countryId));
        }

        if (leagueId) {
          conditionalAdd(params, QUERY_PARAMS.LEAGUE_ID, Number(leagueId));
        }

        sendWsMessage(ACTION_TYPE.GET_PREMATCH, {
          ...convertKeysToUpperCase(params),
          Language: language,
        });
        if (!sports.length) {
          dispatch(setOpenedSports([sportId]));
          if (countryId) {
            dispatch(setOpenedCountries([countryId]));
          }

          dispatch(setIsLoading(false));

          return;
        }
      } else {
        dispatch(setIsLoading(true));
        sendWsMessage(ACTION_TYPE.GET_PREMATCH, {
          Language: language,
        });
      }
    };

    if (!isPrimaryDatsLoaded) return;
    if (reloadPrematch) {
      handleLoadPrematch();
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [
    reloadPrematch,
    sportId,
    dateTo,
    language,
    isPrimaryDatsLoaded,
    isMobileOrTablet,
    activeTabGroup,
  ]);

  useEffect(() => {
    const loadDateBy = async () => {
      dispatch(setIsLoading(true));
      const params: Partial<PrematchParams> = {};

      if (sportId) {
        conditionalAdd(params, QUERY_PARAMS.SPORT_ID, Number(sportId));
      }

      if (countryId) {
        conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, Number(countryId));
      }

      if (leagueId) {
        conditionalAdd(params, QUERY_PARAMS.LEAGUE_ID, Number(leagueId));
      }

      conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateTo);

      dispatch(setLoadByDate(true));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH, {
        ...convertKeysToUpperCase(params),
        Language: language,
      });
    };

    if (isPrimaryDatsLoaded && dateTo) {
      loadDateBy();
    }
  }, [sportId, dateTo, isMobileOrTablet, isPrimaryDatsLoaded, language]);

  return {
    sports,
    isLoading: isLoading,
    isFetching: isLoading,
  };
};
