import { useEffect, useState } from 'react';

import { TIME_RANGES } from '~types/timeRanges';

import { useQueryParams } from './useQueryParams';

export const useNextHourToDateTo = (): string | undefined => {
  const { nextHour } = useQueryParams();
  const [dateTo, setDateTo] = useState<string | undefined>(undefined);

  const getDateTo = () => {
    const date = new Date();

    if (!nextHour) {
      return undefined;
    }

    if (nextHour === TIME_RANGES.TODAY) {
      date.setHours(23, 59, 59, 999);

      return date.toISOString();
    }

    date.setHours(date.getHours() + parseInt(nextHour, 10));

    return date.toISOString();
  };

  useEffect(() => {
    setDateTo(getDateTo());
  }, [nextHour]);

  return dateTo;
};
