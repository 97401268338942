import { useCallback, useEffect, useState } from 'react';

import { InPlayMenuCountries, InPlayMenuSports } from '~api/sportEvent/types';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsFavoriteEventsOpen,
  setIsTopLiveEventsOpen,
} from '~store/slices/liveGroupsSlice';
import { setOpenedSports } from '~store/slices/liveMenuSlice';
import {
  setBreadcrumbsLabel,
  updateLiveMenuState,
} from '~store/slices/menusSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

export const useLiveSportMobileMenu = (sports: InPlayMenuSports) => {
  const { menu, group, sportId } = useQueryParams();
  const dispatch = useAppDispatch();
  const {
    openedSports: [activeSportId],
  } = useAppSelector((state) => state.liveMenu);
  const { updateQueryParams, removeQueryParams } = useRouterQuery();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isTopLiveEventsOpen, isFavoriteEventsOpen } = useAppSelector(
    (state) => state.liveGroupsState,
  );

  let defaultCountriesData: InPlayMenuCountries = [];

  if (sports && activeSportId) {
    const sport = sports.find((sport) => sport.id === parseInt(activeSportId));

    if (sport) {
      defaultCountriesData = sport?.countries || [];
    }
  }

  const [countriesData, setCountriesData] =
    useState<InPlayMenuCountries>(defaultCountriesData);

  // useLoadLiveTopGames();

  const handleOnValueChange = (
    sportId: string,
    updateQuery: boolean = true,
  ) => {
    dispatch(setIsFavoriteEventsOpen(false));
    dispatch(setIsTopLiveEventsOpen(false));
    dispatch(setOpenedSports(sportId));

    if (updateQuery) {
      updateQueryParams({
        [QUERY_PARAMS.SPORT_ID]: sportId,
        [QUERY_PARAMS.MENU]: SPORT_MENUS.LIVE,
      } as QueryParams<typeof QUERY_PARAMS>);

      dispatch(updateLiveMenuState({ sportId }));

      if (group) {
        dispatch(setIsTopLiveEventsOpen(false));
        dispatch(setBreadcrumbsLabel(null));

        removeQueryParams([QUERY_PARAMS.GROUP]);
      }
    }
  };

  const getSportEventsCount = useCallback(
    (countries: InPlayMenuCountries | undefined) => {
      if (!countries) {
        return 0;
      }

      return countries.flatMap((country) =>
        country.leagues.flatMap((league) => league.events),
      ).length;
    },
    [],
  );

  useEffect(() => {
    let sportIdValue = sportId || sports[0]?.id.toString();

    const isInSports = sports.some(
      (sport) => sport.id === parseInt(sportIdValue || ''),
    );

    if (!isInSports) {
      sportIdValue = sports[0]?.id.toString();
    }

    if (sportIdValue && !isLoaded) {
      if (!isFavoriteEventsOpen) {
        handleOnValueChange(sportIdValue, false);
      }

      setIsLoaded(true);
    }
  }, [sports, isLoaded, isFavoriteEventsOpen, sportId]);

  useEffect(() => {
    if (sports && activeSportId) {
      const sport = sports.find(
        (sport) => sport.id === parseInt(activeSportId),
      );

      if (sport) {
        setCountriesData(sport?.countries || []);
      }
    }
  }, [activeSportId, sports]);

  useEffect(() => {
    const activeMenu = menu || SPORT_MENUS.PREMATCH;

    if (activeMenu === SPORT_MENUS.LIVE && group) {
      dispatch(setIsTopLiveEventsOpen(true));
    }

    if (activeMenu === SPORT_MENUS.LIVE && !group) {
      dispatch(setIsTopLiveEventsOpen(false));
      dispatch(setBreadcrumbsLabel(null));
    }
  }, [menu, group]);

  return {
    activeSportId: !(isTopLiveEventsOpen || isFavoriteEventsOpen)
      ? activeSportId
      : undefined,
    isTopLiveEventsOpen,
    isFavoriteEventsOpen,
    countriesData,
    handleOnValueChange,
    getSportEventsCount,
  };
};
