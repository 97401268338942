import { memo, useEffect, useRef, useState } from 'react';

import { useLiveEventConnection } from '~components/molecules/InplayEvents/hooks/useLiveEventConnection';
import { InplayEvent } from '~components/molecules/InplayEvents/InplayEvent';
import { MARKET_STATUS } from '~constants/common';
import { SIGNAL_R_SOCKET_MESSAGES } from '~constants/signalR';
import { useEventsLoadingSocket, useQueryParams } from '~hooks';
import useInplaySocket from '~hooks/useInplaySocket';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsEventLoaded,
  setLiveEventData,
  setLoadingEventId,
  setMarketGroups,
} from '~store/slices/liveMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { Market, SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

export const InplayEventConnection = memo(() => {
  const dispatch = useAppDispatch();
  const search = useQueryParams();
  const { eventId } = search;
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();
  const { listening } = useInplaySocket();
  const language = useAppSelector(selectLanguage);

  const { liveEventData, isEventLoaded, loadingEventId, marketGroups } =
    useAppSelector((state) => state.liveMenu);

  const [eventData, setEventData] = useState<SportEvent | null>(null);
  const eventDataRef = useRef<SportEvent | null>(null);

  const updateEventData = (newData: SportEvent | null) => {
    setEventData(newData);
    eventDataRef.current = newData;
  };

  useEffect(() => {
    updateEventData(liveEventData);
  }, [liveEventData]);

  useEffect(() => {
    if (eventId && isPrimaryDatsLoaded) {
      dispatch(setLoadingEventId(eventId));
      sendWsMessage(ACTION_TYPE.GET_IN_PLAY_EVENT, {
        Id: eventId,
        Language: language,
      });
    }

    return () => {
      dispatch(setIsEventLoaded(false));
      dispatch(setLiveEventData(null));
      dispatch(setMarketGroups({}));
    };
  }, [eventId, isPrimaryDatsLoaded, language]);

  useLiveEventConnection({ loaded: !!eventData });

  useEffect(() => {
    const stopListening = listening({
      [SIGNAL_R_SOCKET_MESSAGES.BET_STOP_MESSAGE]: (data) => {
        if (!eventDataRef.current) return;
        const eventId = data.id;

        if (eventDataRef.current?.id !== eventId) return;
        const resultEvent = { ...eventData };

        resultEvent.markets = resultEvent.markets?.map((market) => {
          market.status = MARKET_STATUS.SUSPENDED;

          return market;
        });

        updateEventData(resultEvent as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.EXTRA_DATA_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { extraData, id } = response;
        const eventRef = eventDataRef.current;

        if (id !== eventRef.id) return;

        updateEventData({ ...eventRef, extraData } as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.MARKET_UPDATE_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { active, ...updatedEventData } = response;
        const { eventId } = updatedEventData;
        const eventRef = eventDataRef.current;

        if (eventId !== eventRef.id) return;

        const eventCopy = { ...eventRef };

        eventCopy.marketsCount = active;

        eventCopy.markets = eventCopy.markets?.map((market: Market) => {
          if (market.id === response.id) {
            return response;
          }

          return market;
        });

        updateEventData(eventCopy as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.MARKETS_UPDATE_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { markets, eventId, active } = response;
        const eventRef = eventDataRef.current;

        if (eventId !== eventRef.id) return;

        const eventCopy = { ...eventRef };

        eventCopy.marketsCount = active;

        eventCopy.markets = eventCopy.markets?.map((eventMarket) => {
          const idMakret = eventMarket.id;
          const market = markets.find(
            (market: Market) => market.id === idMakret,
          );

          if (market) {
            return market;
          } else {
            return eventMarket;
          }
        });

        updateEventData(eventCopy as SportEvent);
      },
    });

    return stopListening;
  }, []);

  return (
    <InplayEvent
      eventData={eventData}
      isEventLoaded={isEventLoaded}
      loadingEventId={loadingEventId}
      marketGroups={marketGroups}
    />
  );
});
