import { Box } from '~components/atoms/Box';
import { useLaunchGame } from '~components/molecules/Games/hooks/useLaunchGame';

export const GamePage = () => {
  const { launchUrl } = useLaunchGame();

  return (
    <Box
      fullWidth
      css={{
        height: 'calc(100vh - 152px)',
        '& > iframe': {
          width: 'inherit',
          height: 'inherit',
        },
        '@xs_sm': {
          height: '100vh',
        },
      }}
    >
      {launchUrl && (
        <iframe allowFullScreen={true} frameBorder={0} src={launchUrl} />
      )}
    </Box>
  );
};
