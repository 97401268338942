import React, { useMemo } from 'react';
import { registerSW } from 'virtual:pwa-register';

import { AbsoluteDialogs } from '~components/atoms/AbsoluteDialogs';
import { DesktopLayout } from '~components/atoms/layouts/DesktopLayout';
import { MobileLayout } from '~components/atoms/layouts/MobileLayout';
import { ConnectionStatus } from '~components/molecules/ConnectionStatus';
import { useMedia } from '~hooks/useMedia';
import { useOnAppInit } from '~useOnAppInit';
const intervalMS = 1000 * 60 * 10;

let isUpdating = false;

registerSW({
  onRegisteredSW(_, registration) {
    const updateServiceWorker = () => {
      if (isUpdating) return;
      isUpdating = true;

      registration.update().finally(() => {
        isUpdating = false;
      });
    };

    setInterval(updateServiceWorker, intervalMS);
  },
});

const App = () => {
  useOnAppInit();
  const { isMobileOrTablet } = useMedia();
  const isMobile = useMemo(() => navigator.userAgent.indexOf('Mobi') > -1, []);

  return useMemo(() => {
    return (
      <>
        {isMobileOrTablet || isMobile ? <MobileLayout /> : <DesktopLayout />}
        <AbsoluteDialogs />
        <ConnectionStatus />
      </>
    );
  }, [isMobileOrTablet, isMobile]);
};

export default App;
