import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Countries, Leagues, PrematchData, Sports } from '~api/category/types';
import {
  InPlaySportEventsGroupedByDate,
  LiveDataMock,
  TopPrematchEvents,
} from '~api/sportEvent/types';

export interface PrematchMenuState {
  prematchMenuData: PrematchData | null;
  leagueEvents: InPlaySportEventsGroupedByDate;
  loadingCountryId: number | null;
  selectedLeagueData: LiveDataMock | null;
  sports: Sports | undefined;
  isLoaded: boolean;
  prematchSports: Sports | undefined;
  openedCountries: string[];
  openedSports: string[];
  selectedLeagueId?: string;
  leaguesData: Record<string, Leagues>;
  reloadPrematch: boolean;
  upcomingEvents: TopPrematchEvents | undefined;
  upcomingEventsLoading?: boolean;
  activeMainHighlightsSportId: string | null;
  maxMarketsCount: number;
  loadByDate: boolean;
  isLoading: boolean;
  isAllLoaded: boolean;
  loadingSportId: number | null;
  countriesData: Record<string, Countries>;
}

const initialState: PrematchMenuState = {
  prematchMenuData: null,
  leagueEvents: [],
  loadingCountryId: null,
  selectedLeagueData: null,
  sports: undefined,
  isLoaded: false,
  prematchSports: undefined,
  activeMainHighlightsSportId: null,
  openedCountries: [],
  openedSports: [],
  selectedLeagueId: undefined,
  leaguesData: {},
  reloadPrematch: true,
  upcomingEvents: undefined,
  upcomingEventsLoading: true,
  maxMarketsCount: 0,
  loadByDate: false,
  isLoading: false,
  isAllLoaded: false,
  loadingSportId: null,
  countriesData: {},
};

export const prematchMenuSlice = createSlice({
  name: 'userUI',
  initialState,
  reducers: {
    setPrematchMenuData: (state, action: PayloadAction<PrematchData>) => {
      state.prematchMenuData = action.payload;
      state.isLoaded = true;
    },
    setSports: (state, action: PayloadAction<Sports | undefined>) => {
      state.sports = action.payload;
    },
    setReloadPrematch: (state, action: PayloadAction<boolean>) => {
      state.reloadPrematch = action.payload;
    },
    setPrematchSports: (state, action: PayloadAction<Sports>) => {
      state.prematchSports = action.payload;
    },
    setOpenedCountries: (state, action: PayloadAction<string[]>) => {
      state.openedCountries = action.payload;
    },
    setOpenedSports: (state, action: PayloadAction<string[]>) => {
      state.openedSports = action.payload;
    },
    addOpenedSport: (state, action: PayloadAction<string>) => {
      state.openedSports = [...state.openedSports, action.payload];
    },
    setLeaguesData: (
      state,
      action: PayloadAction<{ id: string; data: Leagues }>,
    ) => {
      state.leaguesData[action.payload.id] = action.payload.data;
    },
    setPrematchLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    resetLeaguesData: (state) => {
      state.leaguesData = {};
    },
    resetPrematchData: (state) => {
      Object.assign(state, initialState);
      state.reloadPrematch = true;
    },
    setLeagueEvents: (
      state,
      action: PayloadAction<InPlaySportEventsGroupedByDate>,
    ) => {
      state.leagueEvents = action.payload;
    },
    setSelectedLeagueId: (state, action: PayloadAction<string>) => {
      state.selectedLeagueId = action.payload;
    },
    setSelectedLeagueData: (state, action: PayloadAction<LiveDataMock>) => {
      state.selectedLeagueData = action.payload;
    },
    setUpcomingEvents: (state, action: PayloadAction<TopPrematchEvents>) => {
      state.upcomingEvents = action.payload;
    },
    setUpcomingEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.upcomingEventsLoading = action.payload;
    },
    setMaxMarketsCount: (state, action: PayloadAction<number>) => {
      state.maxMarketsCount = action.payload;
    },
    resetPrematchSlice: (state) => {
      Object.assign(state, initialState);
    },
    setLoadByDate: (state, action: PayloadAction<boolean>) => {
      state.loadByDate = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAllLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAllLoaded = action.payload;
    },
    setActiveMainHighlightsSportId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.activeMainHighlightsSportId = action.payload;
    },
    setLoadingCountryId: (state, action: PayloadAction<number | null>) => {
      state.loadingCountryId = action.payload;
    },
    setLoadingSportId: (state, action: PayloadAction<number | null>) => {
      state.loadingSportId = action.payload;
    },
    setCountriesData: (
      state,
      action: PayloadAction<Record<string, Countries>>,
    ) => {
      state.countriesData = action.payload;
    },
  },
});

export const {
  setSports,
  setReloadPrematch,
  setLeagueEvents,
  setSelectedLeagueData,
  setSelectedLeagueId,
  setOpenedCountries,
  setOpenedSports,
  setLeaguesData,
  resetPrematchData,
  setUpcomingEvents,
  setUpcomingEventsLoading,
  addOpenedSport,
  setMaxMarketsCount,
  resetPrematchSlice,
  setLoadByDate,
  setIsLoading,
  setIsAllLoaded,
  setActiveMainHighlightsSportId,
  setLoadingCountryId,
  setLoadingSportId,
  setCountriesData,
} = prematchMenuSlice.actions;

export default prematchMenuSlice.reducer;
