import { BalanceChange, BetAmount, BetslipItem } from '~api/betslip/types';
import { SportEventItem } from '~api/sportEvent/types';
import {
  DEFAULT_BALANCE_CHANGE,
  MIN_SYSTEM_BET_EVENTS,
} from '~components/molecules/Betslip/constants';
import { SystemBetOption, SystemBetOptions } from '~types/betslip';
import { Market, Selection } from '~types/events';
import { calculateCombinations } from '~utils/numberUtils';

interface GetBetslipStakeProps {
  amount: BetAmount;
  separateBonusBalance?: boolean;
  isBet?: boolean;
  systemBetOption?: SystemBetOption | null;
}

/*
  If separateBonusBalance is true, we sum main and bonus amounts
  If separateBonusBalance is false, we need to return either main or bonus amount,
  depending on which balance is selected
*/
export const getBetTotalAmount = (
  amount: BetAmount = { main: '0', bonus: '0' },
  systemBetOption?: SystemBetOption | null,
): number => {
  const { main = 0, bonus = 0 } = amount;
  const mainAmount = Number(main);
  const bonusAmount = Number(bonus);

  if (systemBetOption) {
    return Number(systemBetOption.combinationsCount) * mainAmount;
  }

  return mainAmount + bonusAmount;
};

/*
  We can have either stake (if separateBonusBalance is false)
  or realStake with bonusStake (if true) to check changes and stake with bonusStake to place a bet
*/
export const getBetslipStake = ({
  amount,
  separateBonusBalance = false,
  isBet = false,
}: GetBetslipStakeProps) => {
  const mainAmount = Number(amount?.main || '0');
  const bonusAmount = Number(amount?.bonus || '0');

  // Payload for placing a bet request
  if (isBet) {
    return {
      stake: mainAmount,
      ...(separateBonusBalance && { bonusStake: bonusAmount }),
    };
  }

  // Payload for calculating possible win request
  return separateBonusBalance
    ? { realStake: mainAmount, bonusStake: bonusAmount }
    : { stake: mainAmount };
};

export const calculateSystemBetOptions = (events: number): SystemBetOptions => {
  const results: SystemBetOptions = [];

  if (events < MIN_SYSTEM_BET_EVENTS) {
    return results;
  }

  for (let i = 2; i <= events - 1; i++) {
    // Start from 2 as 2/3 is the smallest valid system bet
    const combinationsCount = calculateCombinations(events, i);

    results.push({
      label: `${i}/${events} (${combinationsCount} opt.)`,
      value: i.toString(),
      combinationsCount: combinationsCount.toString(),
    });
  }

  return results;
};

// Links, used to share bet
export const getShareBetUrl = (id: string) =>
  window.location.origin + '?share=' + id;

export const getShareBetData = (id: string) => {
  const shareUrl = getShareBetUrl(id);

  return {
    url: shareUrl,
    message: `Check my bet at: ${shareUrl}`,
  };
};

export const getStakeAfterTaxes = (stake: number, taxPercent: number): number =>
  stake - stake * (taxPercent / 100);

export const getMarketData = (
  eventData: SportEventItem,
  betslipItem: BetslipItem,
): Market | null => {
  if (!eventData?.markets) return null;
  const { markets } = eventData;
  const { marketId } = betslipItem;

  return markets.find((market) => market.id === marketId) || null;
};

export const getSelectionData = (
  eventData: SportEventItem,
  betslipItem: BetslipItem,
): Selection | null => {
  if (!eventData) return null;

  const market = getMarketData(eventData, betslipItem);

  if (!market) return null;

  return (
    market.selections.find(
      (selection) => selection.id === betslipItem.selectionId,
    ) || null
  );
};

export const getOddFromEvent = (
  eventsData: SportEventItem[],
  betslipItem: BetslipItem,
) => {
  const { eventId, selectionId, marketId } = betslipItem;
  const event = eventsData.find((item) => item.id === eventId);
  const market = event?.markets.find((item) => item.id === marketId);
  const { selections = [] } = market || {};

  return selections.find((item) => item.id === selectionId)?.odd || 0;
};

export const isValidStakeMin = (value: number, minStakeLimit = 0) => {
  if (minStakeLimit === 0 || value === 0) return true;

  return value > 0 && value >= minStakeLimit;
};

export const isValidStakeMax = (value: number, maxStakeLimit = 0) => {
  if (maxStakeLimit === 0 || value === 0) return true;

  return value > 0 && value <= maxStakeLimit;
};

export const calculateAggregatedBetBalanceChanges = (
  balanceChanges: BalanceChange[],
  isSystemBet: boolean,
): BalanceChange => {
  if (!isSystemBet) {
    return balanceChanges[0]!;
  } else {
    // For System bets, we need to aggregate some values from all returned balance changes
    return balanceChanges.reduce(
      (balanceChange: BalanceChange, current) => {
        balanceChange.realBetAmount += current.realBetAmount;
        balanceChange.stakeTax += current.stakeTax;
        balanceChange.possibleIncomeTax += current.possibleIncomeTax;
        balanceChange.totalPossibleWin += current.totalPossibleWin;

        balanceChange.minStakeLimit = current.minStakeLimit;
        balanceChange.maxStakeLimit = current.maxStakeLimit;
        balanceChange.incomeTaxPercent = current.incomeTaxPercent;
        balanceChange.stakeTaxPercent = current.stakeTaxPercent;

        return balanceChange;
      },
      { ...DEFAULT_BALANCE_CHANGE },
    );
  }
};
