import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { useInplayEventBreadcrumb } from '~components/molecules/InplayEvents/hooks/useInplayEventBreadcrumb';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { LIVE_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { QUERY_PARAMS } from '~constants/common';
import { useEventUpdatesListener } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsTopLiveEventsOpen } from '~store/slices/liveGroupsSlice';
import { removeLiveMenuEvent } from '~store/slices/liveMenuSlice';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import { setLiveTabActiveContent } from '~store/slices/mobileSlice';
import { Market } from '~types/events';
import { QueryParams } from '~utils/url';

import { LIVE_GROUP_COMPONENTS } from '../constants';

interface LiveEventCardProps {
  eventId: string;
  isFavoriteEvents?: boolean;
  mainSportMarket?: MainMarket | undefined;
  eventDataProp: SportEventItem;
}

export const useLiveEventCard = ({
  eventId,
  isFavoriteEvents,
  mainSportMarket,
  eventDataProp,
}: LiveEventCardProps) => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const {
    addedSportId,
    addedLeagueId,
    mainMarketsSelected,
    marketsOfFavoriteEventsSelected,
    loadingSportId,
  } = useAppSelector((state) => state.liveMenu);
  const { isFavoriteEventsOpen } = useAppSelector(
    (state) => state.liveGroupsState,
  );
  const marketsSelected = isFavoriteEvents
    ? marketsOfFavoriteEventsSelected
    : mainMarketsSelected;
  const { group, menu } = useQueryParams();
  const [eventData, setEventData] = useState<SportEventItem>(eventDataProp);
  const { sportId, countryId, leagueId } = eventData;
  const { countryName, leagueName, name } = eventData;

  const { populateInplayEventBreadcrumbs } = useInplayEventBreadcrumb({
    menu: MOBILE_MENU.LIVE,
    countryName,
    leagueName,
    name,
  });

  const getMarketData = (event: SportEventItem): Market | undefined => {
    const mapMarkets = (event?.markets || []).filter(
      (item) => item,
    ) as Market[];

    if (!mapMarkets.length) {
      return;
    }

    const eventMarket = mapMarkets?.find(
      ({ marketId }) => parseInt(marketId) === marketsSelected[sportId],
    ) as Market;

    const resMarket = { ...eventMarket };

    const { markets = null } =
      mainMarkets.find(({ id }) => id === sportId) || {};

    if (markets && markets.length) {
      const mainMarketId = mainSportMarket?.id || marketsSelected[sportId];
      const marketData = markets.find(({ id }) => id === mainMarketId);

      resMarket.selections = (resMarket?.selections || []).map(
        ({ selectionId, ...rest }) => {
          const marketItem = marketData?.selections.find(
            ({ id }) => id === selectionId,
          );

          return {
            selectionId,
            ...rest,
            name: marketItem?.name || '',
          };
        },
      );
    }

    return resMarket;
  };

  const [marketData, setMarketData] = useState<Market | undefined>();

  useLayoutEffect(() => {
    setEventData(eventDataProp);
    setMarketData(getMarketData(eventDataProp));
  }, [eventDataProp]);

  const onHandleEventUpdate = useCallback((event: SportEventItem) => {
    setEventData(event);
    setMarketData(getMarketData(event));
  }, []);

  useEventUpdatesListener({ event: eventData, onUpdate: onHandleEventUpdate });

  const [isLoading, setIsLoading] = useState<boolean>(!isFavoriteEvents);

  const { isMobileOrTablet } = useMedia();

  const handleRemoveEvent = useCallback(() => {
    dispatch(
      removeLiveMenuEvent({
        eventId,
        sportId,
        countryId,
        leagueId,
      }),
    );
  }, [eventId, sportId, countryId, leagueId]);

  const handleEventCardClick = useCallback(() => {
    if (group) {
      dispatch(setIsTopLiveEventsOpen(false));
      dispatch(setBreadcrumbsLabel(group));
    }

    updateQueryParams(
      {
        [QUERY_PARAMS.COUNTRY_ID]: countryId,
        [QUERY_PARAMS.LEAGUE_ID]: leagueId,
        [QUERY_PARAMS.MENU]: menu,
        [QUERY_PARAMS.SPORT_ID]: sportId,
        [QUERY_PARAMS.EVENT_ID]: eventId,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );

    if (isMobileOrTablet) {
      dispatch(setLiveTabActiveContent(LIVE_TAB_CONTENTS.LIVE_EVENT));
      if (isFavoriteEventsOpen) {
        dispatch(setBreadcrumbsLabel(LIVE_GROUP_COMPONENTS.FAVORITE_EVENT));
      }

      populateInplayEventBreadcrumbs();
    }
  }, [eventId, sportId, countryId, leagueId, group, isMobileOrTablet]);

  useEffect(() => {
    const isLeagueLoading = Boolean(
      addedLeagueId &&
        parseInt(addedLeagueId) === leagueId &&
        !isMobileOrTablet,
    );
    const isSportLoading = Boolean(
      addedSportId && parseInt(addedSportId) === sportId && !isMobileOrTablet,
    );

    const isSportEventsLoading = Boolean(
      loadingSportId && loadingSportId === sportId && !isMobileOrTablet,
    );

    if (!isFavoriteEvents) {
      setIsLoading(isSportLoading || isLeagueLoading || isSportEventsLoading);
    }
  }, [isMobileOrTablet, addedLeagueId, addedSportId, loadingSportId]);

  return {
    isLoading,
    ...eventData,
    eventData,
    isMobile: isMobileOrTablet,
    market: marketData,
    handleEventCardClick,
    handleRemoveEvent,
  };
};
