import { Box } from '~components/atoms/Box';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';

import { getNavigationItems } from '../../constants/navigationItems';
import { NavigationItem } from '../NavigationItem';

export const UserProfileNavigation = () => {
  const isCasinoWebsite = useIsCasinoWebsite();

  return (
    <Box
      flexCol
      alignStart
      gap={3}
      css={{
        p: '$3',
        borderRadius: '$8',
        backgroundColor: '$grayDark',
        color: '$grayMedium',
      }}
    >
      {getNavigationItems(isCasinoWebsite).map(({ icon, title, value }) => (
        <NavigationItem
          key={title}
          value={value}
          icon={icon}
          title={title}
          showActive
        />
      ))}
    </Box>
  );
};
