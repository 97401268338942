import React, { memo, useCallback, useEffect, useState } from 'react';

import { BetslipItem } from '~api/betslip/types';
import { SportEventItem } from '~api/sportEvent/types';
import { Message } from '~components/atoms/Message';
import { Separator } from '~components/atoms/Separator';
import BetslipStakeMainMobile from '~components/molecules/Betslip/components/BetslipStakes/BetslipStakeMainMobile';
import {
  CURRENCY_CODES,
  EVENT_STATUS,
  MARKET_STATUS,
  MESSAGE_TYPES,
} from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { selectBalanceChangeById } from '~store/slices/selectors/betslip';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';
import { Market, Selection } from '~types/events';
import {
  getMarketData,
  getSelectionData,
  isValidStakeMax,
  isValidStakeMin,
} from '~utils/betslip';
import { commaSeparatedNumber } from '~utils/numberUtils';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import {
  BETSLIP_ERRORS,
  DEFAULT_BALANCE_CHANGE,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../../constants';

import BetslipStakeAmount from './BetslipStakeAmount';
import BetslipStakeMain from './BetslipStakeMain';
import {
  MainWrapper,
  StyledBetslipStakeWrapper,
  StyledEventFinishedText,
} from './styled.components';

interface BetslipStakeProps {
  index: number;
  eventsCount: number;
  quickBet: boolean;
  isDuplicated: boolean;
  betslipItem: BetslipItem;
  eventData: SportEventItem;
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  onRemove: (selectionId: string) => void;
  amountMain?: string;
  amountBonus?: string;
}

const BetslipStake = ({
  index,
  quickBet,
  isDuplicated,
  amountMain = '0',
  amountBonus = '0',
  betslipItem,
  eventData: propEventData,
  stakeType,
  onRemove,
}: BetslipStakeProps) => {
  const { localized, localizedError } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const [eventData, setEventData] = useState<SportEventItem>(propEventData);
  const [selectionData, setSelectionData] = useState<Selection | null>(
    getSelectionData(propEventData, betslipItem),
  );
  const [marketData, setMarketData] = useState<Market | null>(
    getMarketData(propEventData, betslipItem),
  );
  const [isEventFinished, setIsEventFinished] = useState(false);
  const [isMarketFinished, setIsMarketFinished] = useState(false);

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const { currency } = useAppSelector((state) => state.settings);
  const { minStakeLimit, maxStakeLimit } =
    useAppSelector(selectBalanceChangeById(selectionData?.id)) ||
    DEFAULT_BALANCE_CHANGE;

  useEffect(() => {
    //check is object changed from parent prop
    if (!areTwoObjectsEqual(propEventData, eventData)) {
      onHandleEventUpdate(propEventData);
    }
  }, [propEventData]);

  const onHandleEventUpdate = useCallback((event: SportEventItem) => {
    setEventData(event);
    const marketData = getMarketData(event, betslipItem);
    const selectionData = getSelectionData(event, betslipItem);

    setSelectionData(selectionData);
    setMarketData(marketData);

    const isMarketFinished = marketData?.status !== MARKET_STATUS.ACTIVE;

    const isEventFinished = [
      EVENT_STATUS.ACTIVE,
      EVENT_STATUS.IN_PROGRESS,
      EVENT_STATUS.ABOUT_TO_START,
    ].includes(event?.status);

    setIsEventFinished(!isEventFinished);
    setIsMarketFinished(isMarketFinished);
  }, []);

  if (!eventData || !marketData || !selectionData) return null;

  const isSingleMode = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
  const showBetslipStake =
    eventData && isSingleMode && !(isEventFinished && isMarketFinished);

  const totalAmount = Number(amountMain) + Number(amountBonus);
  const isValidMin = isValidStakeMin(totalAmount, minStakeLimit);
  const isValidMax = isValidStakeMax(totalAmount, maxStakeLimit);
  const showErrorMessage =
    !quickBet &&
    (!isValidMin || !isValidMax) &&
    !isEventFinished &&
    !isMarketFinished &&
    isSingleMode &&
    isUserLoggedIn;

  const renderErrorMessage = (minValid: boolean, maxValid: boolean) => {
    if (!showErrorMessage) return null;

    const errorType = !minValid
      ? BETSLIP_ERRORS.MIN_STAKE
      : BETSLIP_ERRORS.MAX_STAKE;
    const errorText = localizedError(`betslip.errors.${errorType}`, {
      ...(!maxValid && { max: commaSeparatedNumber(maxStakeLimit, true) }),
      ...(!minValid && { min: commaSeparatedNumber(minStakeLimit, true) }),
      currency: CURRENCY_CODES[currency] || '',
    });

    return (
      <Message
        css={{
          padding: '$2 $3 !important',
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '0 !important',

          '@xs_sm': {
            padding: '$1 $3 !important',
          },
        }}
        type={MESSAGE_TYPES.WARNING}
        text={errorText}
      />
    );
  };

  return (
    <MainWrapper
      isAggregatedBet={stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE}
    >
      <StyledBetslipStakeWrapper isInvalid={showErrorMessage}>
        {isMobileOrTablet ? (
          <BetslipStakeMainMobile
            index={index}
            quickBet={quickBet}
            onRemove={onRemove}
            eventData={eventData}
            selectionData={selectionData}
            marketData={marketData}
            isDuplicated={isDuplicated}
            isSingleMode={isSingleMode}
            isBetFinished={isMarketFinished || isEventFinished}
          />
        ) : (
          <BetslipStakeMain
            index={index}
            quickBet={quickBet}
            onRemove={onRemove}
            eventData={eventData}
            selectionData={selectionData}
            marketData={marketData}
            isDuplicated={isDuplicated}
            isSingleMode={isSingleMode}
            isBetFinished={isMarketFinished || isEventFinished}
          />
        )}

        {showBetslipStake && (
          <>
            <Separator
              verticalSpace={0}
              shrinkOut={3}
              css={{
                backgroundColor: '$grayDarker',
                '@xs_sm': {
                  mt: '$1',
                },
              }}
            />
            <BetslipStakeAmount
              index={index}
              quickBet={quickBet}
              amountMain={amountMain}
              amountBonus={amountBonus}
              selectionId={selectionData.id}
              odd={selectionData.odd}
            />
          </>
        )}
        {(isEventFinished || isMarketFinished) && (
          <>
            <Separator
              verticalSpace={0}
              shrinkOut={3}
              css={{
                backgroundColor: '$grayDarker',
              }}
            />

            <StyledEventFinishedText>
              {localized(
                `betslip.${isMarketFinished ? 'marketFinished' : 'betFinished'}`,
              )}
            </StyledEventFinishedText>
          </>
        )}
      </StyledBetslipStakeWrapper>
      {renderErrorMessage(isValidMin, isValidMax)}
    </MainWrapper>
  );
};

export default memo(BetslipStake);
