import {
  BalanceManagementIcon,
  BetHistoryIcon,
  BonusesIcon,
  InviteFriendsIcon,
  TransactionsIcon,
  UserProfileIcon,
} from '~icons';
import { SVGElement } from '~types/general';

import { userBonusesTabs } from '../components/UserBonusesTabs';
import { userPaymentsTabs } from '../components/UserPaymentsTabs';
import { USER_PROFILE_NAVIGATION_TABS } from '../components/UserProfileDialog/constants';
import { userProfileTabs } from '../components/UserProfileTabList';

export interface NavigationItem {
  icon: SVGElement;
  title: string;
  value: string;
  subItems?: NavigationItem[];
  onClick?: () => void;
}

export const navigationItems: NavigationItem[] = [
  {
    icon: UserProfileIcon,
    title: 'userProfile.menuTitles.personalProfile',
    value: USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE,
  },
  {
    icon: BalanceManagementIcon,
    title: 'userProfile.menuTitles.balanceManagement',
    value: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
  },
  {
    icon: TransactionsIcon,
    title: 'userProfile.menuTitles.transactions',
    value: USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY,
  },
  {
    icon: BetHistoryIcon,
    title: 'userProfile.menuTitles.history',
    value: USER_PROFILE_NAVIGATION_TABS.HISTORY,
  },
  {
    icon: BonusesIcon,
    title: 'userProfile.menuTitles.bonuses',
    value: USER_PROFILE_NAVIGATION_TABS.BONUSES,
  },
  {
    icon: InviteFriendsIcon,
    title: 'userProfile.menuTitles.inviteFriends',
    value: USER_PROFILE_NAVIGATION_TABS.INVITE_FRIENDS,
  },
];

export const getNavigationItems = (isCasinoWebsite: boolean) =>
  isCasinoWebsite
    ? navigationItems.filter(
        (item) => item.value !== USER_PROFILE_NAVIGATION_TABS.HISTORY,
      )
    : navigationItems;

export const userProfileMobileMenuMap = {
  [USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE]: userProfileTabs,
  [USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT]: userPaymentsTabs,
  [USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY]: null,
  [USER_PROFILE_NAVIGATION_TABS.HISTORY]: null,
  [USER_PROFILE_NAVIGATION_TABS.BONUSES]: userBonusesTabs,
  [USER_PROFILE_NAVIGATION_TABS.INVITE_FRIENDS]: null,
  // Temporary hidden
  // [USER_PROFILE_NAVIGATION_TABS.MESSAGES]: null,
};
