import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Separator } from '~components/atoms/Separator';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';

import { WarningAndCopywriting } from '../../../atoms/WarningAndCopywriting';
import { SubscribeEmail } from '../SubscribeEmail';
import { useFooter } from '../useFooter';

import {
  StyledBottomPlaceholder,
  StyledFooter,
  StyledLinkContentMenuText,
  StyledMenuText,
} from './styled.components';

export const FooterDesktop = ({ isInfoPage }: { isInfoPage?: boolean }) => {
  const { isLaptop, contentMenus } = useFooter();

  return (
    <Box
      flexRow
      justifyCenter
      fullWidth
      fullHeight
      css={{
        position: 'relative',
        display: 'grid',
        backgroundColor: '$blackMain',
      }}
    >
      <Box
        css={{
          position: 'absolute',
          top: '$5',
          right: '$5',
        }}
      >
        <SettingsDropdown />
      </Box>
      <StyledFooter>
        <Box flexCol alignCenter fullWidth>
          <Box
            alignCenter
            fullWidth
            css={{
              display: 'flex',
              flexDirection: isInfoPage || isLaptop ? 'column' : 'row',
              gap: '$8',
              '@lg_xl': {
                width: !isInfoPage ? '100%' : '1280px',
                gap: '$3',
                flexDirection: !isInfoPage ? 'column' : 'row',
                alignItems: 'center',
              },
              '@xl': {
                width: '100%',
              },
            }}
          >
            <SubscribeEmail />
            <Box
              flexRow
              fullWidth={!isLaptop}
              css={{
                justifyContent: isLaptop ? 'space-between' : 'space-evenly',
                alignSelf: isLaptop ? 'stretch' : 'unset',
                pt: isLaptop ? '$9' : 0,
              }}
            >
              {contentMenus ? (
                contentMenus.map(({ name, items }) => (
                  <Box flexCol gap={4} key={name}>
                    <StyledMenuText>{name}</StyledMenuText>
                    <Box flexCol gap={4}>
                      {items.map(({ name, url }) => (
                        <NavigationLink
                          css={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: isLaptop ? '10px' : '12px',
                          }}
                          to={url || ''}
                          key={name}
                        >
                          <StyledLinkContentMenuText as="span">
                            {name}
                          </StyledLinkContentMenuText>
                        </NavigationLink>
                      ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box />
              )}
            </Box>
          </Box>
          <Separator
            shrinkOut={64}
            verticalSpace={0}
            css={{ marginTop: '60px' }}
          />
          <Box flexRow fullWidth css={{ py: '$5' }}>
            {/* a temporary block, it will be populated by backend */}
            <StyledBottomPlaceholder>
              <WarningAndCopywriting />
            </StyledBottomPlaceholder>
          </Box>
        </Box>
      </StyledFooter>
    </Box>
  );
};
